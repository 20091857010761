import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ArticlePage = ({location}) =>
  (
    <Layout>

      <SEO title="Used car sales in lockdown, turning the corner?"
        description="With strict lockdowns in place in Australia’s two largest car markets, cars are taking longer to sell than ever. But have we now turned the corner?"
        location={location}
      />

      <PageHeader
        title="Used car sales in lockdown, turning the corner?"
        text="With strict lockdowns in place in Australia’s two largest car markets, cars are taking longer to sell than ever. But have we now turned the corner?"
      />

      <SectionPageMargin>

          <Heading2>How long does it take to sell a car during lockdown?</Heading2>

          <Paragraph1>
            We are talking to hundreds of prestige car owners each week, most of whom are in Melbourne or Sydney and want to know what impact the current COVID lockdowns will have on the time it takes to sell their car.
          </Paragraph1>

          <Paragraph1>
            Using our market database, we decided to take a look.
          </Paragraph1>

          <Paragraph1>
            We looked at Dealer and Private sale used prestige car ($40k+) sales in NSW for the two months prior to lockdown, compared to the two months post-lockdown (25th June 2021)
          </Paragraph1>


          What we found was;
          <ul>
            <li>There has been a 44% increase in “Days to Sell” (how long it takes to sell a car on average)</li>
            <li>The average Days to Sell was previously 27 days (a pretty typical industry average), but more recently this has increased to 39 days</li>
            <li>Of cars that are still on the market (not yet sold), the median days from when listed to today is 43 days</li>
            <li>Furthermore, over the same period there has been a 14% reduction in listings, with many sellers deciding to wait until after lockdowns before listing their car for sale</li>
            <li>When comparing Year on Year, there has been a roughly 20% reduction in listings of prestige used cars from August 2021 vs July 2020</li>
          </ul>
 
          <Paragraph1>
            Fortunately there are some positive signs that the prestige used car market is returning to normal.
          </Paragraph1>

          <Paragraph1>
            New car brokers and data specialists Price My Car report that there has been a 12.3% increase in new car enquiries last week, on top of an additional 7% increase the week before.
          </Paragraph1>

          <Paragraph1>
            The Federal Chamber of Automotive Industries report that new car sales in Australia for August 2021 were up 33.1% from August 2020, although still well down from historic averages prior to the pandemic it is encouraging to see national sales bouncing back (but unevenly across states).
          </Paragraph1>

          <Paragraph1>
            And finally, with Thursday’s “road map to freedom”, Sydney’s lockdown is slated to finish in late October which will mean car buying and selling can return somewhat to normal in NSW (at least for those vaccinated).
          </Paragraph1>

          <Paragraph1>
            With potentially a few months of backlog, there could be a rush of people wanting to buy a new or used car prior to summer, and for sellers to swap their dreary lockdown wheels for a new freedom machine.
          </Paragraph1>

      </SectionPageMargin>

     <ReadyToGetStarted />

    </Layout>

  )

export default ArticlePage
